

.page-strategy-list {

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 10px 16px;
  }

  .ant-upload-list {
    display: none;
  }

  .card {

    .card-title {
      //width: 200px;

      margin: 8px 0 15px;
      padding: 0 10px;

      border-left: 3px solid #1890ff;

      //background: linear-gradient(to right, rgb(14, 210, 247), rgba(255, 255, 255, 0));
      //
      //color: #fff;
      //
      //&.spec {
      //  background: linear-gradient(to right, rgb(247, 14, 96), rgba(255, 255, 255, 0));
      //}

    }

    .card-item {
      //padding: 15px;

      //background-color: #f4f4f4;

      margin-left: 15px;

      border-radius: 3px;

      box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);

      overflow: hidden;

      &:first-child {
        margin-left: 0;
      }

      .item-actions {
        display: none;
      }
    }

    .item-tips {
      &:hover {
        color: #0c8cfa;
        .item-actions {
          display: block;
        }
      }
    }

    .card-item-title {
      padding: 10px 15px;
      background-color: #f8f8f8;
    }

    .card-item-content {
      padding: 15px;
      line-height: 32px;
    }

  }

}