
.page-settings {

  .content-panel-common {
    .title {
      line-height: 40px;
      padding: 0 10px;
    }

    .btn-add-tag {

    }

    .tag {
      margin-left: 15px;
      margin-bottom: 10px;
      padding: 0 10px 0 15px;
      line-height: 32px;

      background-color: #f4f4f4;

      border-radius: 2px;

      cursor: pointer;

    }

    .btn-close {
      margin-left: 5px;
      color: #666;
    }
  }
}