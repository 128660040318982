
.page-task-list {

  .panel-top, .panel-bottom {
    padding: 20px;

    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
  }

}

//.modal-task {
//  .ant-upload-list {
//    display: block;
//  }
//}


.tag-total {
  height: 32px;
  line-height: 32px;
  color: #ff4500;
  padding-left: 20px;
}