.page-order-list {
  .tag-test {
    position: absolute;
    left: -4px;
    top: -20px;
    background-color: #ff4500;
    color: #fff;
    border-radius: 2px;
    font-size: 12px;
    padding: 2px 6px;
    transform: scale(0.8);
  }

  .tag-total {
    height: 32px;
    line-height: 32px;
    color: #ff4500;
    padding-left: 20px;
  }

  .date-actions-panel {
    // padding-top: 8px;
    padding-bottom: 15px;
    margin-top: -10px;
    .ant-btn {
      margin-left: 15px;
    }
  }

  .avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-size: cover;
  }

  .ant-table-cell {
    &:hover {
      .panel-detail {
        display: block;
      }
    }
  }

  .panel-detail {
    display: none;
    position: absolute;
    left: 50px;
    top: 5px;
    bottom: 5px;
    right: 5px;
    padding: 5px 10px;
    font-size: 12px;
    background-color: #fff;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  }
}

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

[data-theme='compact'] .site-description-item-profile-wrapper {
  font-size: 12px;
  line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

[data-theme='compact'] .ant-drawer-body p.site-description-item-profile-p {
  font-size: 14px;
  line-height: 1.66667;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}

.demo-download-link {
  position: fixed;
  right: 40px;
  bottom: 66px;
}