
.page-task-list {

  .panel-top, .panel-bottom {
    padding: 20px;

    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
  }


}

.drawer-spec .ant-drawer-body {
  padding: 10px;
}

//.modal-task {
//  .ant-upload-list {
//    display: block;
//  }
//}

