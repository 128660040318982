
.page-salary {

  .content-panel-common {
    background-color: #f4f4f4;
    min-height: 100vh;
  }

  .panel-top, .panel-bottom {
    padding: 20px;

    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
  }

  .panel-bottom {
    margin-top: 20px;
  }

  .coupon-temp-list {
    margin-top: 10px;
  }

  .salary-collapse-list {
  }

  .salary-collapse {
    margin-bottom: 15px;
    background-color: #fff;
    box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.02);

    .ant-collapse-content-box {
      // border-top: 1px solid #f4f4f4;
      padding-top: 0 !important;
    }

    .salary-title { 
      padding: 2px 0;
    }

    &.empty {
      .ant-collapse-content-box {
        padding: 0;
      }

      .anticon-caret-right {
        display: none;
        // opacity: 0;
      }
    }

    &.incharge {
      margin-top: 40px;
    }

    .tag-incharge {
      position: absolute;
      left: 50%;
      margin-left: 30px;
    }

    .ant-table-row {
      &.delivered {
        background-color: #c6ffdf;
        td {
          background-color: #c6ffdf;
        }
      }
    }
  }

  .card-salary {
    // border-radius: 6px;
    // margin-top: 15px;

    // .ant-card-head-title {
    //   text-align: center;
    //   font-weight: bold;
    //   color: #0c8cfa;
    //   font-size: 16px;
    // }

    // .salary-title {
    //   margin: 12px;
    //   padding: 15px;
    //   background-color: #f8f8f8;
    //   border-radius: 6px;
    //   margin-bottom: 15px;
    // }

    // .salary-table-title {
    //   margin: 10px 0;
    //   height: 32px;
    //   line-height: 32px;
    //   font-size: 16px;
    //   color: #0c8cfa;
    // }

    .salary-table {
      // margin: 10px 0;

      // .ant-table-thead {
      //   th {
      //     background-color: #fff;
      //   }
      // }

    }
  }
}

