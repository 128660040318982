
.page-coupon-settings {

  .panel-top, .panel-bottom {
    padding: 20px;

    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
  }

  .panel-bottom {
    margin-top: 20px;
  }

  .coupon-temp-list {
    margin-top: 10px;
  }

}

