
.page-customer-list {
  .avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-size: cover;
  }


  .switch-error {
    &.ant-switch {
      // background-color: #0ac56e;

      &.ant-switch-checked {
        background-color: #ff4500;
      }
    }
  }

}

