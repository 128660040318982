

html, body, #root {
  height: 100%;
}
.ant-upload-list {
  display: none;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.app-layout {
  min-width: 1160px;
  min-height: 100%;
}

.app-layout-right {
  margin: 10px 10px 10px 0;

  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.06);

  background: #fff;
}

.app-sider {
  margin: 10px;

  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.06);

  .sider-header {
    padding: 30px 25px;
  }

  .logo {
    margin-right: 15px;
    width: 50px;
    height: 50px;
    background-image: url('https://code-sprite.oss-cn-shanghai.aliyuncs.com/wx-app/mayi.png');
    background-repeat: no-repeat;
    background-size: 52px 52px;

    background-position: -1px 0;
  }

  .test-wrap {
    padding: 15px 10px 0 25px;
  }
}

.content-panel-common {
  padding: 15px;
}

.filter-pannel-common {
  padding-left: 15px;
}

.ant-table-row-level-0 td {
  word-break: break-all;
  white-space: pre-wrap;
  word-wrap: break-word;
}