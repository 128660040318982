.page-login .login-panel-wrap {
  width: 520px;
  padding-bottom: 30px;
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
}
.page-login .tab-wrap {
  padding-right: 10px;
  height: 70px;
  line-height: 60px;
  color: #555;
  border-bottom: 1px solid #ddd;
}
.page-login .tab-wrap .title {
  height: 70px;
  padding-left: 20px;
}
.page-login .tab-wrap .logo {
  display: block;
  margin-right: 15px;
  width: 50px;
  height: 50px;
  background-image: url('https://code-sprite.oss-cn-shanghai.aliyuncs.com/wx-app/mayi.png');
  background-repeat: no-repeat;
  background-size: 100%;
}
.page-login .tab-wrap .tab .anticon {
  margin-right: 5px;
}
.page-login .tab-wrap .tab:nth-child(2) {
  border-left: 1px solid #ddd;
}
.page-login .tab-wrap .tab.active {
  border-bottom: 3px solid #0c8cfa;
  color: #0c8cfa;
}
.page-login .login_form {
  max-width: 96%;
  /*margin: 0 auto;*/
  padding: 40px;
}
.page-login .nav-link {
  margin-right: 20px;
}
.page-login .login-panel-worker {
  padding-top: 30px;
}
.page-login .login-panel-worker .wx-login-wrap {
  width: 300px;
  height: 300px;
  margin-left: 50px;
  background-size: 230px;
  background-position: 5px;
  background-repeat: no-repeat;
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.page-login .login-panel-worker .wx-login-wrap iframe {
  transform: translate(0, -17px);
}
.page-login .login-panel-worker .wx-logo {
  width: 100px;
  height: 100px;
  background-image: url("../../assets/imgs/wx.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}
