
.page-customer-latent-list {
  .avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-size: cover;
  }

  //.demo-download-link {
  //  position: fixed;
  //  right: 40px;
  //  bottom: 66px;
  //}

  .separate-panel {
    position: fixed;
    right: 40px;
    bottom: 120px;
  }

  .separate-select-panel {
    position: absolute;
    right: 0;
    width: 220px;
    bottom: 46px;

    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);

    border-radius: 4px;

    background-color: #fff;

    .item {
      padding: 0 15px;
      line-height: 36px;
      display: flex;
      justify-content: space-between;

      &+.item {
        border-top: 1px solid #eee;
      }
    }
  }

  .btn-separate {
    color: #09c262;
    cursor: pointer;
  }

  .btn-clear {
    position: fixed;

    right: 60px;
    top: 50%;
    margin-top: -50px;
  }
}

