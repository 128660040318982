
.page-coupon-settings {

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 12px 6px;
  }

  .ant-table-column-sorters {
    padding: 12px 6px;
  }

  .panel-top, .panel-bottom {
    padding: 20px;

    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
  }

  .panel-bottom {
    margin-top: 20px;
  }

  .coupon-temp-list {
    margin-top: 10px;
  }

  .tip-percent {
    color: #f9ae61;
  }

  #chart {
    padding: 30px 20px;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
    section {
      padding: 15px 0 20px;
      font-size: 16px;
    }
    & > div {
      display: flex;
      justify-content: center;
    }
    &.chart-empty {
      box-shadow: none;
    }
  }

  .row-total {
    background-color: #e9fff3;
    td {
      background-color: #e9fff3 !important;
    }
  }

  .tabs-info {
    padding-bottom: 30px;
    margin-top: 30px;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);

    .ant-tabs-tabpane {
      padding: 0 20px;
    }
  }

}

.history-drawer {
  .tip-percent {
    color: #f9ae61;
  }

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 10px 6px;
  }

  .underlined {
    text-decoration: line-through;
  }

  .text-sec {
    color: #e9a50b;
  }

  .text-user {
    color: #e90b68;
  }
}