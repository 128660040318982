.page-pictures .main-title {
  font-size: 18px;
  line-height: 2;
  font-weight: bold;
}
.page-pictures .preview-container {
  margin-top: 10px;
  font-family: Microsoft YaHei, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  width: fit-content;
}
.page-pictures .preview-container > .inner {
  position: relative;
  width: 1114px;
  background-color: #dfe3ef;
  padding: 42px 42px 52px 42px;
  border-radius: 10px;
  box-sizing: content-box;
}
.page-pictures .preview-container .mask-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  transform: rotate(-26deg);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.page-pictures .preview-container .mask-mayi {
  width: 260px;
  height: 130px;
  background-image: url(../../assets/imgs/mask-mayi-simple.png);
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: center;
  opacity: 0.2;
}
.page-pictures .preview-container .btn-logout {
  font-size: 16px;
  line-height: 38px;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 120px;
  height: 38px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  background: #2854b2;
}
.page-pictures .preview-container .logo-mayi {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 80px;
  height: 80px;
  background-image: url(../../assets/imgs/mayi.png);
  background-size: 100%;
}
.page-pictures .preview-container .header .auction {
  font-size: 30px;
  font-weight: 700;
  line-height: 58px;
  width: 100%;
  height: 58px;
  text-align: right;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAnCAYAAACfdBHBAAAAAXNSR0IArs4c6QAAA+ZJREFUWAnNWUtrU0EU/s4kNeYmoim9LS7UhbopCBUXdSFFXfqqCqmhltpVtaKg1ZXVglUUfKAVRP+AILoR/4C4EteCQptWRajUggRRsDbpeCba26R3bnIfU+iBcGe+c+acL5Mzj3MDLINMwNrnuLW7FtsOaKYhzLhZ9DIOJCTEPQcRogctuXanb7BhnDzBugjIjVUcpXxc1TfUMUr+E6z1AF1ycSNqg53rc+ERAaPk50C3mLyl5SQk6w7qddoB9UFj5D8i2U6gHu+QZKMlNeytD64xRr4IUT+vJQaRyVavh+CcnRFGyOeROsGz3uZ49WoQGtAgHnipg+KRyU8BlgTd9h2YqBN2V4dv+xqGkcn/QuoKAXaNGG6VoEdANuZWBEMikZ9AUuXvhWAhlTW1whang4+rHhGJvERslHO9odqlz56gq1jbnfFprTULTX4Sq1XeHtZ69QdmkChd92eqtwpFXgKxEuKct5HlJBqzrWG9hCKfR/oUL9LQQR2yRDHERehJCEz+M5Bh4tccAlEbRB1ozh0J4yYw+TlYIxwo0kJzESV5H+gPvPADkR9HmlOFBlzBIwO0EXaBr9LBJBB5ds35yXm6HCLoMtLZQIedb/LjsDo5140c6/rvzldpS9zR6/SoL/K8NTbwYWTsQqWnwihRb5CS0Rf5SaQG2bO6Ciy/BCgZ65LnRWrzzA8vP+v/EVTJ2Hys10+8uuQJqnzzKO38RAhjQ+Dcr18yxmv5zsPawcT7atnwr/KB9d+W2Pxe7M+/B8TrxX65lYTEdqjiRCvlknEI0xjSqv+DvIF4C6fMGzbYqbOQkG8JxZ4tmM3r9HUxO5uGEDd5cs5obaUs4g9tReHpJ62eQc+0mUDquBdx5WwVSt2hiSsHM89/Ynr+HKQcU12XEMWxSt514RWAlvwXIMnp4Fna8axPbcLvyQo/IZvPSzzwledgoqNoyu320mvJzyLNL46IXyB5itqBtGM9R3grmrxVrInJh14lo4tAHskN/Lqu5j1DVU/8xuBGzaB+lE1de3jRHqhtyiVjs+jX2bgWLC/SZwxmdcZLMZ79l4w94c/S3aZsuj+zqzgWW+fe0Ugm+TTdy5N0np8+7kryO2ZnN6PwolDJocqxKu3mfRJXTvhLHuKH+mjlazz1jpNrm1u5MGcLT7dFNUKNSCRUDXG2EnfShmeRDJV2lf7NtSUGlpaMDnn+Q6Cf54Hv6ytUNCVjmfwMsIY3Dz4wVriokrHlWOcCyzL5AlIjDJgt7RYimH/y1fxfySjySGzhdKlaCObjmfSoSsYffEUvHzTxUV6rPrYrkwQi+hJyOGjJGDGi+eF/AZxbqxP54D9KAAAAAElFTkSuQmCC) 255px 50% no-repeat;
  background-size: 56px 46.5px;
  color: #2854b2;
}
.page-pictures .preview-container .header .auction p {
  margin-right: 255px;
}
.page-pictures .preview-container .header .info {
  display: flex;
  font-size: 24px;
  font-weight: 700;
  margin-top: 26px;
  color: #2854b2;
}
.page-pictures .preview-container .header .info .tip {
  padding-left: 100px;
  width: 50%;
}
.page-pictures .preview-container .header .text-mask {
  position: relative;
}
.page-pictures .preview-container .panel-wrap {
  margin-top: 18px;
  justify-content: space-between;
}
.page-pictures .preview-container .panel-left {
  position: relative;
  width: 542px;
  height: 665px;
  border-radius: 10px;
  background: #fff;
}
.page-pictures .preview-container .panel-left .title {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeCAYAAABaKIzgAAAAAXNSR0IArs4c6QAAA0ZJREFUWAnVl0FW2zAQQGfsPLJsblDzXgndAS1dk54g4QTQExB2kE3dDbAjPQHpDdIT1FkDD9i1hYWPQHclL/Z0xrXzFGFbIjHvtV7EljSa+RrNjBSEOZ7XVwdeHOEWIHpI1PqrAtO3qpBCboUEGAJCGCMFd2+OR6qE7TfaCq5c9dZhQjsOQgcAPdt5eXIENOT+4ditfw03/Ps8Gb3PCNq8ONxhIX9RON1w1iaAgePGn75vnIRZX967EPS5AXUYE/AjUNliJ6JT9mBLV/bcbYa9l937sXn0Wbc1A7p60dtjgb4uVNTmWPuFBNcyzgkTPJJD4mQDjxAaCLj2aLywg4IHt76txm8C6l35jaXo4YyVdQrnpgMMd8NyQezC4HbjKIE0zZFxqRRR7LQYvMNG26Y54l1y4X1mAxPIyfgbIqyXTWbAL45Lvinoy3SoY83zng9IXV70C7Vf/U5gEfZv3x4NcPX8MOB6uKUKzHwTjSIg/+7dSTDTX0EjdVLXBIxuvIySPBhRoK9M4o9Zdn9uHg8rYCpVIWFBExzkOSxG+JB4VDTosBKH5OJuFh+lVtJB0UFEM9s4wfqNmhAmPVwSB+ywnUwug5T2NOunsJzFD7V6x2Tg1flBi5W2bU4qXrjsypBjfGSK8ZXL3q5DcKZCzoBKQ7bARhFn7imvsCFznvpwghhPojyOqUdNBnlbpKyc8iZ4JlmbcQEeu0v7pp3LdFmB8kHQ5wl72aSq3sRhRjVOFot6XAr6lINgXnj27H1M8bap/NUsDCwXyaQlLEkUrhKh6hlZZG3ye91Fp8NyknhreXrYUw10HC9vTO0r9agIisF6NL7mz5fZxASQsD+uLfVtY0yqhAvo67VSz+7Mhv42gsqEaeni444hb/jC27IF1A2uXh52gZCTEsAWUtdR2hbYNKlK5WwGpYJIvbSR/e9krLbetKq0OrTlzx4Q8VEKoYMY8lVwpCaYSU/Z+MKgzcveR74183Wt6KQivrvi/qLAC4E2L3pDVtAu84SMSa3kU2h53gQUHY78zPvwDdxPSpVBAf8VsT4qi1Qt5FFRqpauPCNVlaCFQctgq4IUG5WA5sFWCVkpqApLiF35+yB9/+wjNfU54P4ASyh9GWoK83sAAAAASUVORK5CYII=) 170px 50% no-repeat;
  background-size: 46.2px 33px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #6378a5;
  font-size: 28px;
  font-weight: 700;
  line-height: 98px;
  height: 98px;
  text-align: right;
  color: #fff;
}
.page-pictures .preview-container .panel-left .content {
  font-size: 18px;
  padding: 40px 50px;
  color: #4a4a4a;
  line-height: 24px;
}
.page-pictures .preview-container .panel-left .content a {
  color: #0000ee;
  text-decoration: underline !important;
}
.page-pictures .preview-container .panel-left .footer {
  position: absolute;
  bottom: 0;
  width: 542px;
  height: 326px;
  background: url(../../assets/imgs/ad@1x.20ec62ba.png) 50% 50% no-repeat;
  background-size: 100%;
}
.page-pictures .preview-container .panel-right {
  position: relative;
  width: 542px;
  height: 665px;
  border-radius: 10px;
  background: #fff;
  left: 0;
}
.page-pictures .preview-container .panel-right .mask {
  position: absolute;
  z-index: 2;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(74, 74, 74, 0.5);
}
.page-pictures .preview-container .panel-right .modal {
  width: 496px;
  height: 361px;
  position: absolute;
  background: #fff;
  font-size: 22px;
  color: #4a4a4a;
  top: 50%;
  left: 50%;
  margin-left: -248px;
  margin-top: -182px;
  z-index: 9;
  border-radius: 10px;
}
.page-pictures .preview-container .panel-right .modal .btn-confirm {
  margin-top: 40px;
  width: 200px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  text-align: center;
  background: #2854b2;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
}
.page-pictures .preview-container .panel-right .modal-title {
  height: 70px;
  line-height: 70px;
  text-align: center;
  background-color: #2854b2;
  color: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.page-pictures .preview-container .panel-right .modal-content {
  width: 426px;
  height: 160px;
  margin: 20px auto;
  line-height: 180%;
}
.page-pictures .preview-container .panel-right .title {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #2854b2;
  font-size: 28px;
  font-weight: 700;
  line-height: 98px;
  height: 98px;
  text-align: center;
  color: #fff;
  background-size: 46.2px 33px;
}
.page-pictures .preview-container .panel-right .tip {
  margin-top: 18px;
  text-align: center;
}
.page-pictures .preview-container .panel-right .tip .version {
  font-size: 14px;
  line-height: 60px;
  float: left;
  width: 180px;
  height: 38px;
  margin-left: 0;
  color: #4a4a4a;
}
.page-pictures .preview-container .panel-right .tip .btn-query {
  float: right;
  line-height: 38px;
  width: 258px;
  height: 38px;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
  background: #2854b2;
  font-size: 18px;
}
.page-pictures .preview-container .panel-right .content {
  font-size: 20px;
  width: 450px;
  height: 265px;
  margin: 0 auto;
  padding-top: 6px;
  color: #2854b2;
  color: #4a4a4a;
  line-height: 26px;
}
.page-pictures .preview-container .panel-right .footer {
  font-size: 18px;
  width: 450px;
  margin: 0 auto;
  padding: 30px 0;
  color: red;
}
.page-pictures .preview-container .panel-right .footer .inner {
  padding: 25px 25px 30px;
  background: #f5f8fd;
}
.page-pictures .preview-container .panel-right .footer .footer-title {
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  color: #2854b2;
  line-height: 31px;
}
.page-pictures .preview-container .panel-right .footer .footer-content {
  font-size: 18px;
  min-height: 96px;
  margin-top: 15px;
  text-align: left;
  background-size: 120px auto;
  line-height: 24px;
}
.page-pictures .preview-container .gpfooter {
  font-size: 20px;
  position: absolute;
  right: 50px;
  color: #2854b2;
  bottom: 12px;
}
